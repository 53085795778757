import { CmsThemes } from 'src/lib/constants';

export const ctaAssetBannerContainerStyles = {
  [CmsThemes.WEB2]: {},
  [CmsThemes.WEB3]: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: '15px',
      opacity: '0.25',
      backgroundImage: (theme) => {
        return theme.palette['web3/component/shadow'];
      },
      filter: 'blur(8px)',
    },
  },
};

export const ctaAssetBannerStyles = {
  [CmsThemes.WEB2]: {
    border: '1px solid var(--black600)',
    borderRadius: '8px',
  },
  [CmsThemes.WEB3]: {
    border: 'none',
    borderRadius: '15px',
  },
};

export const ctaAssetContentWrapperStyles = ({ isFlipped }) => {
  return {
    [CmsThemes.WEB2]: {
      padding: {
        xs: '32px 12px 32px 22px',
        md: '32px 27px 28px 22px',
        xl: '128px 70px 128px 82px',
      },
      backgroundColor: 'white',
      borderTop: { xs: '1px solid var(--black600)', sm: 'none' },
      ...(!isFlipped
        ? {
            borderLeft: { sm: '1px solid var(--black600)' },
          }
        : {
            borderRight: { sm: '1px solid var(--black600)' },
          }),
      '& h2, & h3': {
        marginBottom: '10px',
      },
      '& p': {
        color: (theme) => {
          return theme.palette['base/black/800'];
        },
      },
    },
    [CmsThemes.WEB3]: {
      padding: {
        xs: '32px 14px',
        md: '71px 74px',
      },
      '& h2, & h3': {
        marginBottom: '16px',
      },
      backgroundColor: (theme) => {
        return theme.palette['web3/component/card/background'];
      },
    },
  };
};
