import React from 'react';
import { Link } from 'src/components-v2/Inputs/Link';
import { getSlideStyles } from '../linkCarouselStyles';
import { Column } from 'src/components-v2/Layout';
import { animated } from '@react-spring/web';

const AnimatedColumn = animated(Column);

const StationaryLinkCarousel = ({
  slides,
  springs,
  focusOnCard,
  setIsHovered,
  handleHover,
  handleLeave,
  isHovered,
}) => {
  return slides.map((slide, index) => {
    return (
      <AnimatedColumn
        style={springs[index]}
        key={`slide-column-${index}`}
        xsOffset={1}
        lgOffset={index === 0 ? 2 : 1}
        sm={7}
        lg={6}
        xs={22}
        sx={{
          textAlign: { xs: 'center', sm: 'left' },
          ...getSlideStyles(isHovered),
          ...slide.sx,
        }}
      >
        <Link href={slide.href}>
          <a
            onFocus={() => {
              focusOnCard(index);
            }}
            onBlur={() => {
              return setIsHovered(false);
            }}
            onMouseEnter={() => {
              handleHover(index);
            }}
            onMouseLeave={() => {
              handleLeave();
            }}
          >
            {slide.content}
          </a>
        </Link>
      </AnimatedColumn>
    );
  });
};

export default StationaryLinkCarousel;
