export const isSafari = () => {
  if (navigator.userAgentData) {
    // Check directly for "Safari" in brands
    return navigator.userAgentData.brands.some((brand) => {
      return brand.brand === 'Safari';
    });
  } else {
    // Fallback for older browsers
    return (
      navigator.userAgent.includes('Safari') &&
      !navigator.userAgent.includes('Chrome') &&
      !navigator.userAgent.includes('Chromium')
    );
  }
};

export const isFirefox = () => {
  if (navigator.userAgentData) {
    // Check directly for "Safari" in brands
    return navigator?.userAgent.toLowerCase().includes('firefox');
  } else {
    // Fallback for older browsers
    return (
      navigator.userAgent.includes('Firefox') &&
      !navigator.userAgent.includes('Chrome') &&
      !navigator.userAgent.includes('Chromium')
    );
  }
};

export const isWindows = () => {
  if (navigator?.userAgentData?.platform) {
    return navigator?.userAgentData.platform.toLowerCase().includes('win');
  } else {
    return (
      navigator?.userAgent?.toLowerCase()?.includes('win') ??
      navigator?.platform?.toLowerCase()?.includes('win')
    );
  }
};
