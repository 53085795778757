import React from 'react';
import ThemeProvider from 'threads5/themes/theme-provider';
import styled from 'threads5/styles/styled';

import { Box } from 'src/components-v2/Layout';
import { CmsComponentSwitch } from 'src/cms/CmsComponentSwitch';
import {
  ModeContextProvider,
  useCmsMode,
} from 'src/contexts/ModeContext/ModeContext';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import useWorkerGuilloche from 'src/hooks/useWorkerGuilloche';
import { IGuillocheDetail } from '../Guilloche/Guilloche.types';

const sanitizeNumberInput = (input?: string | number): string | number => {
  if (typeof input === 'string') {
    return input.replace(/[^0-9.-]+/g, '');
  }
  return input;
};

const guillocheModules = {
  dune: () => {
    return import('../Guilloche/Dune');
  },
  ribbon: () => {
    return import('../Guilloche/Ribbon');
  },
  donut: () => {
    return import('../Guilloche/Donut');
  },
  puddle: () => {
    return import('../Guilloche/Puddle');
  },
};

export const Canvas = styled('canvas')({});

export const BackgroundContainer = ({ fields, sys, ...props }) => {
  const guilloche = React.useRef<IGuillocheDetail | null>(null);
  const [isGuillocheLoaded, setIsGuillocheLoaded] = React.useState(false);

  React.useEffect(() => {
    const loadGuilloche = async () => {
      if (fields?.asset?.fields) {
        const pattern = fields.asset.fields.pattern;
        const importer = guillocheModules[pattern];
        if (importer) {
          try {
            const webModule = await importer();
            guilloche.current = webModule.guillocheInfo;
            setIsGuillocheLoaded(true);
          } catch (error) {
            // do something
          }
        }
      }
    };

    loadGuilloche();
  }, [fields?.asset]);

  const canvas = React.useRef<HTMLCanvasElement>(null);
  const bgImg = fields?.asset?.fields?.backgroundAsset?.fields?.file?.url;
  const gradient = bgImg ? 'https:' + bgImg + '?fm=webp&q=1' : undefined;
  useWorkerGuilloche({
    canvasRef: canvas,
    disableAnimation: fields?.asset?.fields?.disableAnimation,
    gradient,
    horizontalWave: fields?.asset?.fields?.xAxis,
    id: sys?.id,
    intensity: fields?.asset?.fields?.intensity,
    intersectionThreshold: fields?.asset?.fields?.intersectionThreshold,
    rotate: sanitizeNumberInput(fields?.asset?.fields?.translate?.rotate),
    scale: sanitizeNumberInput(
      fields?.asset?.fields?.translate?.scale ??
        fields?.asset?.fields?.translate?.z,
    ),
    scaleX: sanitizeNumberInput(fields?.asset?.fields?.translate?.scaleX),
    scaleY: sanitizeNumberInput(fields?.asset?.fields?.translate?.scaleY),
    speed: fields?.asset?.fields?.speed,
    svgHeight: guilloche?.current?.height,
    svgPaths: guilloche?.current?.paths,
    svgWidth: guilloche?.current?.width,
    verticalWave: fields?.asset?.fields?.yAxis,
    x: sanitizeNumberInput(fields?.asset?.fields?.translate?.x),
    y: sanitizeNumberInput(fields?.asset?.fields?.translate?.y),
    zAxisWave: fields?.asset?.fields?.zAxis,
  });

  const mode = useCmsMode({
    mode: fields?.mode,
    backgroundColor: fields?.backgroundColor,
  });
  const componentTheme = useComponentTheme(mode);

  // NOTE: we have to key into the theme because for whatever reason tokens aren't working on the background css property
  const background =
    componentTheme.palette[fields?.backgroundColor] ||
    fields.backgroundColor?.toLowerCase() ||
    'transparent';

  // we naively override child background color and mode
  // this will only update the first level of components
  // most children will/should override bg color and mode
  const components = fields?.components?.map((component) => {
    return {
      ...component,
      fields: {
        ...component.fields,
        backgroundColor: 'transparent',
        mode,
        intrinsicMode: component?.fields?.mode,
      },
    };
  });

  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  return (
    <ModeContextProvider mode={mode}>
      <ThemeProvider theme={componentTheme}>
        <Box
          className='background-container'
          sx={{
            position: 'relative',
            overflow: 'hidden',
            background,
            ...fields?.styles,
          }}
          {...inspectorProps({ fieldId: 'components' })}
        >
          {fields?.asset?.fields && (
            <Canvas
              id={sys?.id}
              className='guilloche-canvas'
              ref={canvas}
              sx={{
                position: 'absolute',
                background: 'transparent',
                top: '50%',
                left: '50%',
                transform: `translate(-50%, -50%)`,
                width: '100%',
                height: '100%',
                opacity: isGuillocheLoaded ? 1 : 0,
                transition: 'opacity 0.5s ease-in-out',
                // NOTE: we are no longer allowing the styles field to be used
              }}
            />
          )}
          <Box
            sx={{ position: 'relative' }}
            className='background-container-inner'
          >
            <CmsComponentSwitch {...props} components={components} />
          </Box>
        </Box>
      </ThemeProvider>
    </ModeContextProvider>
  );
};
