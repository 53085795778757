import * as React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import ThemeProvider from 'threads5/themes/theme-provider';

import { useCmsMode } from 'src/contexts/ModeContext/ModeContext';
import { Wrapper } from 'src/containers';
import { Column, Stack } from 'src/components-v2/Layout';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { CmsMediaSwitch } from 'src/components-v2/Media/CmsMediaSwitch';
import { useComponentTheme } from 'src/hooks/useComponentTheme';

const CmsLargeAssetTopCenteredHero = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor?.toLowerCase() || 'transparent';
  const mode = useCmsMode({
    mode: fields?.mode,
    backgroundColor: bgColor,
  });
  const componentTheme = useComponentTheme(mode);
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  const { asset } = fields || {};
  const background = componentTheme.palette[fields?.backgroundColor];
  const fieldId = 'LargeImageBody';

  return (
    <ThemeProvider theme={componentTheme}>
      <Wrapper
        component='header'
        sx={{ background: background, ...fields?.styles }}
        {...inspectorProps({ fieldId: 'internalTitle' })}
      >
        <Column xs={24} sm={24} lg={20} lgOffset={2} className='image-column'>
          {asset && (
            <CmsMediaSwitch
              asset={{
                ...asset.fields,
                isLazy: false,
                styles: {
                  height: 'auto',
                  width: '100%',
                  ...asset.fields?.styles,
                },
              }}
              queryString={{
                xs: '?fm=webp&w=1226&q=60',
                sm: '?fm=webp&w=1884&q=60',
                md: '?fm=webp&w=2398&q=60',
                lg: '?fm=webp&w=2742&q=60',
              }}
              width={1371} // setting a default width in case it was not provided in CMS
              height={572} // setting a default height in case it was not provided in CMS
              sys={asset.sys}
            />
          )}
        </Column>
        <Column
          className='content-column'
          xs={24}
          smOffset={3}
          sm={18}
          lgOffset={4}
          lg={16}
        >
          <Stack
            gap={3}
            alignItems='center'
            sx={{
              textAlign: 'center',
              mt: '40px',
              '& h1': {
                fontSize: '1.6rem',
              },
              '.button-group': {
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2,
              },
              '& p': {
                maxWidth: '816px',
              },
              '& p:nth-last-of-type(1):empty': {
                display: 'none',
              },
            }}
          >
            <RichTextSwitch
              sys={sys}
              content={fields?.centerColumn}
              nodes={{
                h2: {
                  component: 'h2',
                  variant: 'h1',
                  fieldId,
                },
                h6: {
                  component: 'h1',
                  variant: 'h6',
                  className: 'eyebrow-heading',
                  fieldId,
                },
                p: {
                  component: 'p',
                  variant: 'h5',
                  fieldId,
                },
              }}
            />
          </Stack>
        </Column>
      </Wrapper>
    </ThemeProvider>
  );
};

export default CmsLargeAssetTopCenteredHero;
