import { useState, useEffect } from 'react';
import { isFirefox, isSafari, isWindows } from 'src/lib/browser-utils';
// import { isDevelopment } from 'src/lib/constants';
import { useReducedMotion } from '@react-spring/web';
import { useTrack } from 'src/contexts/AnalyticsContext';
import { PERFORMANCE_EVENTS } from 'src/lib/trackingEvents';

const useIsPotato = ({
  withTracking = false,
}: { withTracking?: boolean } = {}) => {
  const track = useTrack();
  const [isLowPerformance, setIsLowPerformance] = useState(false);
  // const [isLongTask, setIsLongTask] = useState(false);
  const prefersReducedMotion = useReducedMotion();

  useEffect(() => {
    const checkPerformance = () => {
      // we force a device into potato mode when there are less than 10 CPU cores
      // after testing a range of devices I've found that anything with less than 10 cores does not perform well
      // NOTE: pretty much no mobile phone will pass this test, however we have observed that mobile phones
      // are able to animate some guilloche patterns without significant performance issues
      const isLowConcurrency =
        navigator.hardwareConcurrency && navigator.hardwareConcurrency < 10;

      // TODO: try to get these working ordered by most important
      // 1. windows
      // 2. safari
      // 3. firefox
      const isBrowserSafari = isSafari();
      const isBrowserFirefox = isFirefox();
      const isUserOnWindows = isWindows();

      const isLowPerformanceDevice =
        isLowConcurrency ||
        isBrowserSafari ||
        isBrowserFirefox ||
        isUserOnWindows ||
        prefersReducedMotion;

      if (withTracking) {
        if (isLowPerformanceDevice) {
          track({ type: PERFORMANCE_EVENTS.IS_LOW_PERFORMANCE });
        } else {
          track({ type: PERFORMANCE_EVENTS.IS_NOT_LOW_PERFORMANCE });
        }
      }

      setIsLowPerformance(isLowPerformanceDevice);
    };

    checkPerformance();
  }, [prefersReducedMotion, withTracking, track]);

  // useEffect(() => {
  //   let observer;
  //   // Check if PerformanceObserver is supported
  //   if (typeof PerformanceObserver === 'function' && !isLongTask) {
  //     observer = new PerformanceObserver((list) => {
  //       for (const entry of list.getEntries()) {
  //         // the recommendation for what is a perceptible long task is 200ms
  //         // we will set ours to 2000ms because some our tasks on a fast device are
  //         // taking more than 1300ms
  //         if (entry.duration > 700) {
  //           if (!isDevelopment) {
  //             setIsLongTask(true);
  //           }
  //           break;
  //         }
  //       }
  //     });

  //     try {
  //       observer.observe({ type: 'longtask', buffered: true });
  //     } catch (e) {
  //       // eslint-disable-next-line no-console
  //       console.warn('PerformanceObserver couldn’t observe long tasks', e);
  //     }
  //   } else {
  //     // we assume that any browser that does not support PerformanceObserver is a potato
  //     setIsLongTask(true);
  //   }

  //   return () => {
  //     if (observer) {
  //       observer.disconnect();
  //     }
  //   };
  // }, [isLongTask]);

  return isLowPerformance;
};

export default useIsPotato;
